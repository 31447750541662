// src/components/AuthContext.js

import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { createApiInstance, setupCSRF, apiRequest as axiosApiRequest } from './axiosConfig';
import { authGet } from './auth';
import { clearAllIndexedDBData } from '../indexeddb';
import { syncFavorites } from '../syncFavorites';

const AuthContext = createContext(null);
const MAX_RETRY_ATTEMPTS = 3;

// Custom error class for registration errors
class RegistrationError extends Error {
  constructor(message, errors = null) {
    super(message);
    this.name = 'RegistrationError';
    this.errors = errors;
  }
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [csrfToken, setCsrfToken] = useState(null);
  const navigate = useNavigate();
  
  const initializeRef = useRef(false);
  const authCheckTimeoutRef = useRef(null);

  // 配置 axios 默认值
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.timeout = 60000;
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    
    if (process.env.REACT_APP_API_BASE_URL) {
      axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
    }
  }, []);

  // 刷新 CSRF Token
  const refreshCSRFToken = useCallback(async () => {
    try {
      const token = await setupCSRF();
      if (token) {
        setCsrfToken(token);
      }
      return token;
    } catch (err) {
      console.error('❌ Failed to refresh CSRF token:', err);
      throw err;
    }
  }, []);

  // 统一的 API 请求处理方法
  const apiRequest = useCallback(async (config, retryCount = 0) => {
    try {
      // Create a new instance for each request to ensure fresh headers
      const instance = createApiInstance();
      const response = await axiosApiRequest(config, instance);
      return response;  // Return the entire response, not just response.data
    } catch (err) {
      if (err.response?.status === 401) {
        setUser(null);
      }
      throw err;
    }
  }, []);

  // 清理用户认证相关的所有存储
  const clearAuthStorage = useCallback(() => {
    // 清除认证相关的所有cookie
    Cookies.remove('browser_id');
    Cookies.remove('sessionid');
    
    // 清除本地存储
    localStorage.removeItem('browser_id');
    localStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('browser_id');
    
    console.log('🧹 Cleared all auth-related storage');
  }, []);

  // 设置认证状态
  const setAuthenticationState = useCallback((userData) => {
    console.log('🔐 Setting authentication state:', {
      userData,
      isAnonymous: userData?.is_anonymous_user,
      hasEmail: !!userData?.email
    });
    
    if (userData) {
      // 用户已登录，不管验证状态如何
      clearAuthStorage();
      localStorage.setItem('isAuthenticated', 'true');
      setUser(userData);
    } else {
      // 用户未登录
      clearAuthStorage();
      setUser(null);
    }
    
    console.log('🔐 Authentication state updated:', { 
      user: userData,
      isAuthenticated: !!userData,
      isAnonymous: userData?.is_anonymous_user
    });
  }, [clearAuthStorage]);

  // 更新检查认证状态的方法
  const checkAuth = useCallback(async () => {
    try {
      console.log('🔒 Starting auth status check...');
      const response = await apiRequest({
        method: 'get',
        url: '/sort/auth/user-info/',
        validateStatus: (status) => {
          return status === 200 || status === 403;
        }
      });
      
      console.log('🔒 Auth check response:', {
        status: response.status,
        data: response.data,
        isAnonymous: response.data?.data?.is_anonymous_user
      });
      
      if (response.status === 200 && response.data.data) {
        const userData = response.data.data;
        console.log('👤 User data received:', {
          email: userData.email,
          isAnonymous: userData.is_anonymous_user,
          isPremium: userData.is_premium,
          isVerified: userData.is_verified
        });

        // 检查用户是否为匿名用户
        if (!userData.is_anonymous_user) {
          console.log('✅ Setting authenticated user state');
          setAuthenticationState(userData);
          
          // 检查 URL 参数，看是否需要显示验证成功消息
          const params = new URLSearchParams(window.location.search);
          if (params.get('verification_success')) {
            console.log('🎉 Email verification success detected');
          }
          
          return true;
        } else {
          console.log('❌ User is anonymous, clearing auth state');
          setAuthenticationState(null);
          return false;
        }
      }
      
      console.log('❌ No valid user data, clearing auth state');
      setAuthenticationState(null);
      return false;
    } catch (err) {
      console.error('❌ Auth check failed:', err);
      setAuthenticationState(null);
      return false;
    }
  }, [apiRequest, setAuthenticationState]);

  // Initialize effect
  useEffect(() => {
    const initialize = async () => {
      if (initializeRef.current) return;
      initializeRef.current = true;

      console.log('🚀 Starting auth initialization...');
      try {
        // 使用 Promise.all 和 setTimeout 来并行异步执行初始化任务
        Promise.all([
          // CSRF token 刷新
          new Promise((resolve) => {
            setTimeout(async () => {
              try {
                await refreshCSRFToken();
                console.log('✅ CSRF token refreshed');
              } catch (err) {
                console.warn('⚠️ CSRF token refresh failed:', err);
                // 不阻止继续初始化
              }
              resolve();
            }, 0);
          }),
          
          // 用户认证状态检查
          new Promise((resolve) => {
            setTimeout(async () => {
              try {
                await checkAuth();
                console.log('✅ Auth check complete');
              } catch (err) {
                console.error('❌ Auth check failed:', err);
                setUser(null);
              }
              resolve();
            }, 0);
          })
        ]).finally(() => {
          setIsInitialized(true);
          console.log('✅ Auth initialization complete');
        });
        
      } catch (err) {
        console.error('❌ Failed to initialize auth:', err);
        setError('Failed to initialize application');
        setUser(null);
        setIsInitialized(true);
      }
    };

    initialize();

    // 在 effect 内部创建一个变量来存储 timeout ref
    const timeoutRef = authCheckTimeoutRef.current;

    // 清理函数使用这个变量
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [checkAuth, refreshCSRFToken]);

  // Axios 拦截器 - 移除不必要的日志记录，因为已经在 apiRequest 中处理
  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        if (csrfToken) {
          config.headers['X-CSRFToken'] = csrfToken;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axios.interceptors.response.use(
      response => response,
      error => Promise.reject(error)
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [csrfToken]);

  // 更新登录方法
  const login = useCallback(async (credentials) => {
    try {
      // 不需要显式调用 refreshCSRFToken
      const formData = new FormData();
      formData.append('login', credentials.email);
      formData.append('password', credentials.password);
      formData.append('remember', credentials.remember ? 'on' : '');

      const response = await apiRequest({
        method: 'post',
        url: '/sort/login/',
        data: formData,
        headers: {
          'Accept': 'application/json',
        }
      });

      if (response.status === 200) {
        // 清除匿名用户相关的存储
        clearAuthStorage();
        // 清除所有 IndexedDB 数据
        await clearAllIndexedDBData();
        // 设置认证状态和登录状态
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('loginStatus', 'loggedIn');
        // 重新获取用户信息
        await checkAuth();
        // 从服务器同步最新数据
        await syncFavorites();
        
        console.log('🔑 Login successful');
        
        return {
          success: true,
          location: response.data.location || '/app',
          data: response.data
        };
      }

      throw new Error(response.data.error || 'Invalid response from server');
    } catch (err) {
      console.error('❌ Login failed:', err);
      if (err.response) {
        const errorMessage = err.response.data.error || 
                           err.response.data.message || 
                           'Authentication failed';
        throw new Error(errorMessage);
      }
      throw err;
    }
  }, [apiRequest, checkAuth, clearAuthStorage]);

  // 注册
  const register = useCallback(async (userData) => {
    try {
      // 不需要显式调用 refreshCSRFToken
      const formData = new FormData();
      formData.append('email', userData.email);
      formData.append('password1', userData.password1);
      formData.append('password2', userData.password2);

      const response = await apiRequest({
        method: 'post',
        url: '/sort/signup/',
        data: formData,
        headers: {
          'Accept': 'application/json',
        }
      });

      if (response.status === 200) {
        const redirectLocation = response.data.location || '/app';
        
        if (response.data.data) {
          await checkAuth();
        }
        
        return {
          success: true,
          location: redirectLocation,
          message: response.data.message || 'Registration successful',
          data: response.data.data || null
        };
      }

      throw new Error('Unexpected response from server');
    } catch (err) {
      console.error('Registration error details:', {
        error: err,
        response: err.response?.data,
        status: err.response?.status
      });

      if (err.response?.data) {
        const errorData = err.response.data;
        
        if (errorData.errors) {
          throw new RegistrationError(
            errorData.message || 'Registration failed',
            errorData.errors
          );
        }
        
        if (errorData.message) {
          throw new Error(errorData.message);
        }
        
        if (errorData.error) {
          throw new Error(errorData.error);
        }
      }
      
      if (err instanceof RegistrationError) {
        throw err;
      }
      
      throw new Error(err.message || 'An error occurred during registration');
    }
  }, [apiRequest, checkAuth]);

  // 更新登出方法
  const logout = useCallback(async () => {
    try {
      await apiRequest({
        method: 'post',
        url: '/sort/logout/'
      });
    } catch (err) {
      console.error('Logout error:', err);
    } finally {
      // 清除所有 IndexedDB 数据
      await clearAllIndexedDBData();
      setAuthenticationState(null);
      setCsrfToken(null);
      initializeRef.current = false;
      localStorage.removeItem('loginStatus');
      console.log('👋 Logout complete - cleared all auth data');
    }
  }, [apiRequest, setAuthenticationState]);

  // 重发验证邮件
  const resendVerification = useCallback(async () => {
    try {
      const response = await apiRequest({
        method: 'post',
        url: '/sort/auth/resend-verification/'
      });
      return response.data;
    } catch (err) {
      throw new Error('Failed to resend verification: ' + err.message);
    }
  }, [apiRequest]);

  const handleGoogleLogin = useCallback(async () => {
    try {
      console.log('🔄 Initiating Google login...');
      const response = await apiRequest({
        method: 'get',
        url: '/accounts/google/login/',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response?.data?.url) {
        console.log('✅ Redirecting to Google login page');
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('❌ Google login error:', error);
      throw new Error('Failed to initiate Google login');
    }
  }, [apiRequest]);

  const handleSocialLoginSuccess = useCallback(async () => {
    try {
      console.log('🔄 Processing social login success...');
      
      // 清除所有 IndexedDB 数据
      await clearAllIndexedDBData();
      
      await checkAuth();  // 重新获取用户信息
      
      // 清除匿名用户相关的存储
      clearAuthStorage();
      
      // 设置认证状态
      localStorage.setItem('isAuthenticated', 'true');
      
      // 从服务器同步最新数据
      await syncFavorites();
      
      console.log('✅ Social login processed successfully');
    } catch (error) {
      console.error('❌ Error processing social login:', error);
      throw error;
    }
  }, [checkAuth, clearAuthStorage]);

  useEffect(() => {
    const handleSocialLoginCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      if (params.get('social_login') === 'success') {
        try {
          await handleSocialLoginSuccess();
          navigate('/app');  // 登录成功后重定向到应用
        } catch (error) {
          console.error('Failed to process social login callback:', error);
          setError('Failed to complete social login');
        }
      }
    };

    handleSocialLoginCallback();
  }, [handleSocialLoginSuccess, navigate]);

  const value = {
    user,
    loading,
    error,
    login,
    register,
    logout,
    resendVerification,
    checkAuth,
    isAuthenticated: !!user,  // 只检查是否有用户数据
    isVerified: user?.is_verified || false,  // 验证状态仍然保留，但不影响登录状态
    isInitialized,
    clearAuthStorage,
    handleGoogleLogin,
  };

  console.log('Auth state:', { user, loading, isInitialized });

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const ProtectedRoute = ({ children }) => {
  const { user, loading, isInitialized } = useAuth();
  const location = useLocation();

  if (loading || !isInitialized) {
    return (
      <div className="loading-skeleton">
        <div className="skeleton-header"></div>
        <div className="skeleton-content">
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>
          <div className="skeleton-line"></div>
        </div>
      </div>
    );
  }

  if (!user) {
    console.log('🚫 Access denied - redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthContext;