// src/components/payment/PaymentSuccess.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../../css/Payment.css';  // Suggested to create a dedicated style file

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [redirectStatus, setRedirectStatus] = useState('');
  const [showManualButton, setShowManualButton] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  
  // Log immediately when component mounts
  useEffect(() => {
    console.log('PaymentSuccess: Component mounted');
    
    const sessionId = searchParams.get('session_id');
    const platform = searchParams.get('platform');
    
    setSessionId(sessionId);
    
    // Log all URL parameters for debugging
    console.log('PaymentSuccess: URL parameters', { 
      sessionId, 
      platform, 
      pathname: window.location.pathname,
      search: window.location.search,
      href: window.location.href,
      allParams: Object.fromEntries([...searchParams]) 
    });
    
    // Immediately show manual return button, regardless of platform
    setShowManualButton(true);
    
    // If Android platform, try to redirect to the app
    if (platform === 'android' && sessionId) {
      console.log('PaymentSuccess: Detected Android platform');
      setRedirectStatus('Please click the button below to return to the app');
      
      // Try multiple methods to open deep link
      setTimeout(() => {
        try {
          // Method 1: Create hidden a tag and click it
          const deepLink = `linksortai://payment?session_id=${sessionId}`;
          console.log('PaymentSuccess: Attempting to redirect to app with URL:', deepLink);
          
          const linkElement = document.createElement('a');
          linkElement.setAttribute('href', deepLink);
          linkElement.style.display = 'none';
          document.body.appendChild(linkElement);
          linkElement.click();
          
          // Method 2: Directly modify location
          setTimeout(() => {
            console.log('PaymentSuccess: Trying alternative redirect method');
            window.location.href = deepLink;
          }, 300);
          
          console.log('PaymentSuccess: Redirect attempts completed');
        } catch (error) {
          console.error('PaymentSuccess: Error during redirect attempt:', error);
        }
      }, 500);
      
      // Don't verify payment status, and don't automatically redirect to /app
      return;
    }
    
    // Payment verification can be done here
    const verifyPayment = async () => {
      try {
        console.log('PaymentSuccess: Verifying payment with session ID:', sessionId);
        const response = await fetch(`/sort/verify-payment/?session_id=${sessionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Payment verification failed');
        }
        
        console.log('PaymentSuccess: Payment verified successfully');
      } catch (error) {
        console.error('PaymentSuccess: Payment verification error:', error);
        // Can redirect to error page
        navigate('/payment/error');
      }
    };

    if (sessionId) {
      verifyPayment();
    }

    // Only redirect to main app for non-Android platforms
    if (!platform || platform !== 'android') {
      // Redirect to main app after 3 seconds
      const timer = setTimeout(() => {
        console.log('PaymentSuccess: Redirecting to main app');
        navigate('/app');
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [navigate, searchParams]);

  const handleManualReturn = () => {
    if (sessionId) {
      // Try multiple methods to open deep link
      const deepLink = `linksortai://payment?session_id=${sessionId}`;
      console.log('PaymentSuccess: Manual redirect to app with URL:', deepLink);
      
      try {
        // Method 1: Create a tag and click it
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', deepLink);
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
        
        // Method 2: Directly modify location
        setTimeout(() => {
          window.location.href = deepLink;
        }, 100);
      } catch (error) {
        console.error('PaymentSuccess: Error in manual redirect:', error);
        alert('Unable to automatically return to the app. Please open the LinkSort app manually');
      }
    }
  };

  return (
    <div className="payment-success-container">
      <div className="payment-success-content">
        <div className="success-icon">✓</div>
        <h1>Payment Successful!</h1>
        <p>Thank you for upgrading to Pro!</p>
        {redirectStatus ? (
          <p className="redirect-message">{redirectStatus}</p>
        ) : (
          <p className="redirect-message">
            You will be redirected to the app in a few seconds...
          </p>
        )}
        
        {showManualButton && (
          <div style={{ marginTop: '20px' }}>
            <button 
              onClick={handleManualReturn}
              style={{
                padding: '12px 24px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '18px',
                fontWeight: 'bold',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
              }}
            >
              Return to LinkSort App
            </button>
            <p style={{ marginTop: '10px', fontSize: '14px', color: '#666' }}>
              If the button doesn't work, please open the LinkSort app manually
            </p>
            
            {/* Display session ID for debugging */}
            <p style={{ marginTop: '20px', fontSize: '12px', color: '#999' }}>
              Session ID: {sessionId}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess; 