import { saveFavoritesToIndexedDB } from '../indexeddb';
import { createApiInstance } from '../auth/axiosConfig';
import { addToSyncQueue } from '../syncService';
import { handleApiResponse } from '../Toast';
import { FaTimes } from 'react-icons/fa';
import React from 'react';

/**
 * Updates favorite tags both locally and on the server
 * @param {string} url - The URL of the favorite to update
 * @param {Array} tags - The new tags array
 * @param {Array} favorites - The current favorites array
 * @param {Function} setFavorites - Function to update favorites state
 * @param {Function} setFilteredFavorites - Function to update filtered favorites state
 * @returns {Promise<boolean>} - Success status
 */
export const updateFavoriteTags = async (url, tags, favorites, setFavorites, setFilteredFavorites) => {
    try {
        console.log('Updating tags for URL:', url);
        console.log('New tags:', tags);
        
        // First update local state
        const updatedFavorites = favorites.map(fav => {
            if (fav.url === url) {
                return { ...fav, tags: tags };
            }
            return fav;
        });
        
        if (setFavorites) {
            setFavorites(updatedFavorites);
        }
        
        if (setFilteredFavorites) {
            setFilteredFavorites(updatedFavorites);
        }
        
        // Save to IndexedDB
        await saveFavoritesToIndexedDB(updatedFavorites);
        
        // Send to server
        const apiInstance = createApiInstance();
        const response = await apiInstance.post('/sort/update_favorite_tags/', {
            url: url,
            tags: tags
        });
        
        console.log('Tags update response:', response.data);
        handleApiResponse.success('Tags updated successfully');
        
        // Add to sync queue for offline support
        addToSyncQueue({
            type: 'favorites',
            operation: 'update_tags',
            data: {
                url: url,
                tags: tags
            }
        });
        
        return true;
    } catch (error) {
        console.error('Error updating tags:', error);
        handleApiResponse.error('Failed to update tags');
        return false;
    }
};

/**
 * Adds a new tag to a favorite
 * @param {string} newTagText - The tag text to add
 * @param {string} favoriteUrl - The URL of the favorite
 * @param {Array} favorites - The current favorites array
 * @param {Function} setCurrentTags - Function to update current tags state
 * @param {Function} setFavorites - Function to update favorites state
 * @param {Function} setFilteredFavorites - Function to update filtered favorites state
 * @param {Function} setNewTagText - Function to clear the input field
 * @returns {Promise<void>}
 */
export const addTag = async (
    newTagText, 
    favoriteUrl, 
    favorites, 
    setCurrentTags, 
    setFavorites, 
    setFilteredFavorites, 
    setNewTagText
) => {
    if (!newTagText.trim() || !favoriteUrl) return;
    
    const tag = newTagText.trim();
    
    // Find the favorite
    const favorite = favorites.find(fav => fav.url === favoriteUrl);
    if (!favorite) return;
    
    // Check if tag already exists
    const existingTags = favorite.tags || [];
    if (existingTags.includes(tag)) {
        handleApiResponse.info('Tag already exists');
        return;
    }
    
    // Add the tag
    const updatedTags = [...existingTags, tag];
    
    // Update local state
    if (setCurrentTags) {
        setCurrentTags(updatedTags);
    }
    
    // Update on server
    await updateFavoriteTags(favoriteUrl, updatedTags, favorites, setFavorites, setFilteredFavorites);
    
    // Clear input
    if (setNewTagText) {
        setNewTagText('');
    }
};

/**
 * Removes a tag from a favorite
 * @param {string} tagToRemove - The tag to remove
 * @param {string} favoriteUrl - The URL of the favorite
 * @param {Array} favorites - The current favorites array
 * @param {Function} setCurrentTags - Function to update current tags state
 * @param {Function} setFavorites - Function to update favorites state
 * @param {Function} setFilteredFavorites - Function to update filtered favorites state
 * @returns {Promise<void>}
 */
export const removeTag = async (
    tagToRemove, 
    favoriteUrl, 
    favorites, 
    setCurrentTags, 
    setFavorites, 
    setFilteredFavorites
) => {
    if (!favoriteUrl) return;
    
    // Find the favorite
    const favorite = favorites.find(fav => fav.url === favoriteUrl);
    if (!favorite) return;
    
    // Remove the tag
    const existingTags = favorite.tags || [];
    const updatedTags = existingTags.filter(tag => tag !== tagToRemove);
    
    // Update local state
    if (setCurrentTags) {
        setCurrentTags(updatedTags);
    }
    
    // Update on server
    await updateFavoriteTags(favoriteUrl, updatedTags, favorites, setFavorites, setFilteredFavorites);
};

/**
 * Tag Editor Dialog Component
 * @param {Object} props - Component props
 * @returns {JSX.Element|null}
 */
export const TagEditorDialog = ({
    editingTagsUrl,
    favorites,
    currentTags,
    newTagText,
    setNewTagText,
    closeTagEditor,
    handleAddTag,
    handleRemoveTag
}) => {
    if (!editingTagsUrl) return null;
    
    const favorite = favorites.find(fav => fav.url === editingTagsUrl);
    if (!favorite) return null;
    
    return (
        <div className="modal-overlay">
            <div className="modal-content tag-editor-modal">
                <div className="modal-header">
                    <h3>Edit Tags</h3>
                    <button className="close-button" onClick={closeTagEditor}>
                        <FaTimes />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="current-tags-section">
                        <h4>Current Tags</h4>
                        <div className="current-tags">
                            {currentTags.length > 0 ? (
                                <div className="tags-grid">
                                    {currentTags.map((tag, index) => (
                                        <div key={index} className="tag-item">
                                            <span className="tag-text">{tag}</span>
                                            <button 
                                                className="tag-remove-btn"
                                                onClick={() => handleRemoveTag(tag)}
                                            >
                                                <FaTimes size={12} />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p className="no-tags-message">No tags yet</p>
                            )}
                        </div>
                    </div>
                    
                    <div className="add-tag-section">
                        <h4>Add New Tag</h4>
                        <div className="add-tag-form">
                            <input
                                type="text"
                                value={newTagText}
                                onChange={(e) => setNewTagText(e.target.value)}
                                placeholder="Enter tag name"
                                className="tag-input"
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddTag();
                                    }
                                }}
                            />
                            <button 
                                className="add-tag-button"
                                onClick={handleAddTag}
                                disabled={!newTagText.trim()}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="done-button" onClick={closeTagEditor}>
                        Done
                    </button>
                </div>
            </div>
        </div>
    );
}; 