// components/FavoritesList.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteFavoriteFromIndexedDB, saveFavoritesToIndexedDB, getFavoritesFromIndexedDB, getCategoryFromIndexedDB } from './indexeddb';
import { syncFavorites } from './syncFavorites';
import { 
    processImageUrl, 
    handleImageError, 
    handleImageLoad,
    getImageContainerStyle,
    getImageStyle
} from './utils/imageUtils';
import '../css/favorites/favorites-index.css';
import Masonry from 'react-masonry-css';
import { FaComments, FaTrash, FaChevronDown, FaChevronUp, FaLink, FaEdit, FaFilter, FaSearch, FaHashtag, FaPlus, FaLightbulb, FaCheckCircle, FaInfoCircle, FaStar, FaBookOpen, FaTimes, FaTags } from 'react-icons/fa';
import { handleApiResponse } from './Toast';
import { getCategoriesFromIndexedDB } from './indexeddb';
import { handleDeleteCategory, handleRenameCategory, updateFavoriteCategory } from './categoryUtils';
import CategorySelector from './CategorySelector';
import { addToSyncQueue } from './syncService';
import { createApiInstance } from './auth/axiosConfig';
// Import tag utilities
import { updateFavoriteTags, addTag, removeTag, TagEditorDialog } from './utils/tagUtils';

// 在文件开头添加颜色常量
const ICON_COLORS = {
  delete: '#dc3545',  // 红色
  chat: '#4CAF50',    // 绿色
  expand: '#2196F3',  // 蓝色
  filter: '#FF9800',  // 橙色
  edit: '#9C27B0',    // 紫色
  search: '#2196F3',  // 蓝色
  plus: '#4CAF50',    // 绿色
  hash: '#607D8B',    // 灰蓝色
  book: '#FF5722',    // 深橙色
  check: '#4CAF50',   // 绿色
  info: '#2196F3',    // 蓝色
  star: '#FFC107',    // 金色
  link: '#00BCD4',    // 青色
  tag: '#9C27B0',     // 紫色
};

function FavoritesList({ 
    favorites, 
    setFavorites, 
    setFilteredFavorites, 
    getCategoryColor,
    categories,
    activeCategory,
    handleCategoryClick,
    clearCategorySelection,
    setActiveView,
    activeView,
    expandedItemUrl,
    setExpandedItemUrl,
    toggleExpand,
    handleCreateCategory,
    setCategories
}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [showCategories, setShowCategories] = useState(false);
    const [showNewCategoryInput, setShowNewCategoryInput] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const [categoriesData, setCategoriesData] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [loadingIcons, setLoadingIcons] = useState(new Set());
    const [editingItemUrl, setEditingItemUrl] = useState(null);
    const [localCategories, setLocalCategories] = useState([]);
    const [menuTimeout, setMenuTimeout] = useState(null);
    const [categorySearchTerm, setCategorySearchTerm] = useState('');
    const [showCategoryCount, setShowCategoryCount] = useState(false);
    const [editingTagsUrl, setEditingTagsUrl] = useState(null);
    const [newTagText, setNewTagText] = useState('');
    const [currentTags, setCurrentTags] = useState([]);

    // Add click outside handler
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowCategories(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 修改排序逻辑
    const sortedFavorites = [...favorites].sort((a, b) => {
        // 使用 updated_at 进行排序，如果 updated_at 不存在则回退到 created_at
        const dateA = a.updated_at || a.created_at;
        const dateB = b.updated_at || b.created_at;
        return new Date(dateB) - new Date(dateA);
    });

    // 实时过滤收藏列表
    const filteredFavorites = sortedFavorites.filter(fav => {
        const matchesSummary = fav.summary?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesUrl = fav.url?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesTitle = fav.title?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesTags = fav.tags && fav.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));
        
        return matchesSummary || matchesUrl || matchesTitle || matchesTags;
    });

    // 处理删除收藏
    const handleDeleteFavorite = async (favoriteToDelete) => {
        // 添加确认弹窗
        if (!window.confirm('Are you sure you want to delete this favorite?')) {
            return;
        }

        try {
            await deleteFavoriteFromIndexedDB(favoriteToDelete.url);
            const newFavorites = favorites.filter(fav => fav.url !== favoriteToDelete.url);
            setFavorites(newFavorites);
            setFilteredFavorites(newFavorites);
            await saveFavoritesToIndexedDB(newFavorites);
            
            window.dispatchEvent(new CustomEvent('favoriteChanged', {
                detail: {
                    type: 'delete',
                    url: favoriteToDelete.url
                }
            }));
        } catch (error) {
            console.error('Error deleting favorite:', error);
            alert('Failed to delete favorite. Please try again.');
        }
    };

    // 添加同步监听器
    useEffect(() => {
        const handleSync = (event) => {
            if (event.detail.success) {
                // 更新状态或执行其他操作
                getFavoritesFromIndexedDB().then(setFavorites);
            }
        };

        window.addEventListener('favoritesSync', handleSync);
        return () => window.removeEventListener('favoritesSync', handleSync);
    }, [setFavorites]);

    // 初始加载数据
    useEffect(() => {
        const loadData = async () => {
            try {
                // 1. 立即加载本地数据
                const localFavorites = await getFavoritesFromIndexedDB();
                setFavorites(localFavorites);
                
                // 2. 异步加载分类
                getCategoriesFromIndexedDB().then(storedCategories => {
                    const formattedCategories = storedCategories.map(cat => ({
                        name: cat.category,
                        icon_url: cat.icon_url
                    }));
                    setCategoriesData(formattedCategories);
                });

                // 3. 异步同步服务器数据
                syncFavorites();
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };

        loadData();
    }, [setFavorites]);

    useEffect(() => {
        console.log('Favorites data:', favorites);
        favorites.forEach(fav => {
            if (fav.main_image_url) {
                //console.log(`Favorite has image URL: ${fav.main_image_url}`);
            }
        });
    }, [favorites]);

    useEffect(() => {
        const loadCategories = async () => {
            try {
                const storedCategories = await getCategoriesFromIndexedDB();
                // 保留完整的类别数据，包括 icon_url
                const formattedCategories = storedCategories.map(cat => ({
                    name: cat.category,
                    icon_url: cat.icon_url
                }));
                setLocalCategories(formattedCategories);
                console.log('Loaded categories:', formattedCategories);
            } catch (error) {
                console.error('Error loading categories:', error);
            }
        };

        loadCategories();
    }, []);

    const handleCategoryChange = async (url, newCategory) => {
        try {
            const success = await updateFavoriteCategory(url, newCategory);
            if (success) {
                // 更新本地状态
                const updatedFavorites = favorites.map(fav => {
                    if (fav.url === url) {
                        return { ...fav, category: newCategory };
                    }
                    return fav;
                });
                setFavorites(updatedFavorites);
                setEditingItemUrl(null);
            } else {
                // Handle failure case
                handleApiResponse.error('Failed to update category. Please try again.');
            }
        } catch (error) {
            console.error('Error updating category:', error);
            handleApiResponse.error('An error occurred while updating the category.');
        }
    };

    const handleMenuEnter = (e) => {
        if (menuTimeout) {
            clearTimeout(menuTimeout);
            setMenuTimeout(null);
        }
    };

    const handleMenuLeave = (e) => {
        if (!editingItemUrl) {
            setMenuTimeout(setTimeout(() => {
                setEditingItemUrl(null);
            }, 1000));
        }
    };

    const renderFavoriteItem = (fav, index, isNew) => {
        const isExpanded = isNew || fav.url === expandedItemUrl;
        
        return (
            <li 
                key={index} 
                className={`favorite-item ${isExpanded ? 'expanded' : ''}`}
                data-url={fav.url}
            >
                <div className="favorite-preview">
                    {/* 预览内容 */}
                    {fav.main_image_url && (
                        <div 
                            className="article-main-image" 
                            style={{
                                ...getImageContainerStyle(),
                                cursor: 'pointer'
                            }}
                            onClick={() => window.open(fav.url, '_blank')}
                            role="button"
                            tabIndex={0}
                            data-tooltip="Click to view original article"
                        >
                            <img 
                                src={processImageUrl(fav.main_image_url)} 
                                alt={fav.title || 'Article thumbnail'}
                                style={getImageStyle()}
                                onError={(e) => handleImageError(e, fav.main_image_url)}
                                onLoad={(e) => handleImageLoad(e, fav.main_image_url)}
                                crossOrigin="anonymous"
                                loading={index < 2 ? 'eager' : 'lazy'}
                                decoding="async"
                                width="800"
                                height="450"
                                fetchpriority={index < 2 ? 'high' : 'low'}
                            />
                        </div>
                    )}
                    
                    <a href={fav.url} target="_blank" rel="noopener noreferrer">
                        <FaLink style={{marginRight: '6px', fontSize: '12px', color: ICON_COLORS.link}} />
                        {fav.url.length > 50 ? `${fav.url.substring(0, 50)}...` : fav.url}
                    </a>
                    {fav.title && (
                        <h2 
                            onClick={() => toggleExpand(fav.url)}
                            style={{ cursor: 'pointer' }}
                            role="button"
                            tabIndex={0}
                            data-tooltip="Show Summary"
                        >
                            {fav.title}
                        </h2>
                    )}

                    {/* 展开或新收藏时显示完整内容 */}
                    {isExpanded && (
                        <div className="favorite-full-content">
                            {fav.summary && (
                                <div 
                                    className="summary-section"
                                    onClick={() => toggleExpand(fav.url)}
                                    style={{ cursor: 'pointer' }}
                                    role="button"
                                    tabIndex={0}
                                    data-tooltip="Click to collapse"
                                >
                                    <div className="summary-content">
                                        {/* Split summary by sections */}
                                        {fav.summary.split('\n\n').map((section, index) => {
                                            // Check if section starts with "Key Points:"
                                            if (section.startsWith('Key Points:')) {
                                                const points = section.substring('Key Points:'.length).trim().split('\n');
                                                return (
                                                    <div 
                                                        key={index} 
                                                        className="summary-section-item"
                                                    >
                                                        <div className="summary-content-wrapper">
                                                            <ul className="key-points-list">
                                                                {points.map((point, pointIndex) => {
                                                                    const pointText = point.replace(/^-\s*/, '');
                                                                    const [title, detail] = pointText.split(': ').map(s => s.trim());
                                                                    
                                                                    const icons = [
                                                                        <FaCheckCircle className="key-point-icon" style={{ color: ICON_COLORS.check }} />,
                                                                        <FaInfoCircle className="key-point-icon" style={{ color: ICON_COLORS.info }} />,
                                                                        <FaStar className="key-point-icon" style={{ color: ICON_COLORS.star }} />
                                                                    ];
                                                                    const icon = icons[pointIndex % icons.length];
                                                                    
                                                                    return (
                                                                        <li key={pointIndex} className="key-point-item">
                                                                            {icon}
                                                                            <div className="key-point-content">
                                                                                <div className="key-point-title">{title}</div>
                                                                                <div className="key-point-detail">{detail}</div>
                                                                            </div>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            
                                            // Handle overview or other sections
                                            const match = section.match(/^(.*?:)(.*)/);
                                            if (match || !section.includes(':')) {
                                                const label = match ? match[1].trim() : 'Overview';
                                                const content = match ? match[2].trim() : section.trim();
                                                
                                                return (
                                                    <div 
                                                        key={index} 
                                                        className="summary-section-item"
                                                    >
                                                        <FaBookOpen className="summary-icon" style={{ color: ICON_COLORS.book }} />
                                                        <div className="summary-content-wrapper">
                                                            <div className="summary-label">{label}</div>
                                                            <div className="summary-text">{content}</div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            
                                            // Return null for any section that doesn't match the above conditions
                                            return null;
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {/* 始终显示的底部信息 */}
                    <div className="favorite-footer">
                        <div className="footer-content">
                            <div className={`category-container ${editingItemUrl === fav.url ? 'active' : ''}`}>
                                <div className="category-dropdown">
                                    <span 
                                        className="category-tag" 
                                        style={{ 
                                            backgroundColor: getCategoryColor(fav.category),
                                        }}
                                    >
                                        {fav.category}
                                    </span>
                                    
                                    <div 
                                        className="category-actions"
                                        onMouseEnter={handleMenuEnter}
                                        onMouseLeave={handleMenuLeave}
                                    >
                                        <button 
                                            className="category-action-btn filter"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCategoryClick(fav.category);
                                            }}
                                            title="Filter by this category"
                                        >
                                            <FaFilter size={12} style={{ color: ICON_COLORS.filter }} />
                                            Filter
                                        </button>
                                        
                                        <button 
                                            className="category-action-btn edit"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setEditingItemUrl(fav.url);
                                            }}
                                            title="Move to different category"
                                        >
                                            <FaEdit size={12} style={{ color: ICON_COLORS.edit }} />
                                            Move
                                        </button>
                                    </div>
                                </div>
                                
                                {editingItemUrl === fav.url && (
                                    <CategorySelector
                                        currentCategory={fav.category}
                                        categories={localCategories}
                                        onSelect={(newCategory) => handleCategoryChange(fav.url, newCategory)}
                                        onClose={() => setEditingItemUrl(null)}
                                        handleCreateCategory={handleCreateCategory}
                                    />
                                )}
                            </div>
                            
                            <span style={{ float: 'right' }}>
                                {fav.updated_at ? new Date(fav.updated_at).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                }) : (fav.created_at ? new Date(fav.created_at).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                }) : '')}
                            </span>
                        </div>
                        
                        {/* Add tags display */}
                        <div className="tags-container" onClick={() => openTagEditor(fav.url)}>
                            <div className="tags-icon">
                                <FaTags size={14} style={{ color: ICON_COLORS.tag }} />
                            </div>
                            <div className="tags-list">
                                {fav.tags && fav.tags.length > 0 ? (
                                    fav.tags.map((tag, tagIndex) => (
                                        <span key={tagIndex} className="tag-chip">
                                            {tag}
                                        </span>
                                    ))
                                ) : (
                                    <span className="no-tags-hover">
                                        <span className="tags-hover-text">Add tags...</span>
                                    </span>
                                )}
                            </div>
                        </div>
                        
                        <div className="article-actions">
                            <div className="left-actions">
                                <button 
                                    className="icon-button"
                                    onClick={() => handleDeleteFavorite(fav)}
                                    data-tooltip="Delete"
                                >
                                    <FaTrash className="delete-icon" size={20} style={{ color: ICON_COLORS.delete }} />
                                </button>
                            </div>
                            <div className="right-actions">
                                <button 
                                    className="icon-button"
                                    onClick={() => navigate(`/chat/${encodeURIComponent(fav.url)}`)}
                                    data-tooltip="Chat with Article"
                                >
                                    <FaComments className="chat-icon" size={20} style={{ color: ICON_COLORS.chat }} />
                                </button>
                                <button 
                                    onClick={() => toggleExpand(fav.url)}
                                    className="icon-button"
                                    data-tooltip={isExpanded ? 'Show Less' : 'Show More'}
                                >
                                    {isExpanded ? 
                                        <FaChevronUp className="expand-icon" size={20} style={{ color: ICON_COLORS.expand }} /> : 
                                        <FaChevronDown className="expand-icon" size={20} style={{ color: ICON_COLORS.expand }} />
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        );
    };

    const handleNewCategorySubmit = async () => {
        if (!newCategoryName.trim()) {
            handleApiResponse.error('Category name cannot be empty');
            return;
        }

        if (categories.includes(newCategoryName.trim())) {
            handleApiResponse.error('Category already exists');
            return;
        }

        setIsCreating(true);
        try {
            const createdCategory = await handleCreateCategory(newCategoryName.trim());
            if (createdCategory) {
                // 更新本地分类列表
                setCategories(prevCategories => [...prevCategories, newCategoryName.trim()]);
                // 更新分类数据，使用服务器返回的图标URL
                setCategoriesData(prevData => [...prevData, {
                    name: newCategoryName.trim(),
                    icon_url: createdCategory.icon_url
                }]);
            }
            setNewCategoryName('');
            setShowNewCategoryInput(false);
        } finally {
            setIsCreating(false);
        }
    };

    const handleCategoryDelete = async (categoryName, e) => {
        e.stopPropagation(); // 防止触发分类选择

        // 添加确认对话框
        if (!window.confirm(`Are you sure you want to delete the category "${categoryName}"?`)) {
            return;
        }

        setIsDeleting(true);
        try {
            const success = await handleDeleteCategory(categoryName);
            if (success) {
                // 更新本地状态
                const updatedCategories = categoriesData.filter(cat => cat.name !== categoryName);
                setCategoriesData(updatedCategories);
                setCategories(categories.filter(cat => cat !== categoryName));
                
                // 如果删除的是当前选中的分类，清除选择
                if (activeCategory === categoryName) {
                    clearCategorySelection();
                }
            }
        } finally {
            setIsDeleting(false);
        }
    };

    // 修改检查图标的函数
    const checkForNewIcon = async (categoryName, maxAttempts = 10) => {
        for (let attempt = 0; attempt < maxAttempts; attempt++) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/sort/categories/${encodeURIComponent(categoryName)}/icon/`,
                    {
                        credentials: 'include',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    }
                );
                
                if (!response.ok) {
                    console.error(`Server returned ${response.status}: ${response.statusText}`);
                    const text = await response.text();
                    console.error('Response text:', text);
                    continue;
                }

                try {
                    const data = await response.json();
                    if (data.icon_url && data.is_ready) {
                        return data.icon_url;
                    }
                } catch (parseError) {
                    console.error('Error parsing JSON response:', parseError);
                    continue;
                }
            } catch (error) {
                console.error('Network error checking for new icon:', error);
            }
            // 等待1秒后再次检查
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        return null;
    };

    // 修改 handleConfirmRename 函数
    const handleConfirmRename = async (cat) => {
        const oldName = cat.name;
        setLoadingIcons(prev => new Set([...prev, oldName]));
        
        try {
            const success = await handleRenameCategory(oldName, newCategoryName);
            if (success.success) {
                // 立即更新分类名称，但暂时使用占位图标
                setCategoriesData(prevData => prevData.map(category => 
                    category.name === oldName
                        ? { 
                            name: success.category.name,
                            icon_url: null // 暂时清除图标
                        }
                        : category
                ));

                // 开始轮询检查新图标
                const newIconUrl = await checkForNewIcon(success.category.name);
                if (newIconUrl) {
                    // 获取完整的分类信息
                    const categoryData = await getCategoryFromIndexedDB(success.category.name);
                    // 获取到新图标后更新UI
                    setCategoriesData(prevData => prevData.map(category => 
                        category.name === success.category.name
                            ? { 
                                name: success.category.name,
                                icon_url: categoryData ? categoryData.icon_url : newIconUrl
                            }
                            : category
                    ));
                }

                if (activeCategory === oldName) {
                    handleCategoryClick(success.category.name);
                }
                setEditingCategory(null);
                setNewCategoryName('');
            }
        } catch (error) {
            console.error('Error renaming category:', error);
        } finally {
            setLoadingIcons(prev => {
                const next = new Set(prev);
                next.delete(oldName);
                return next;
            });
        }
    };

    useEffect(() => {
        const filterFavorites = (favorites, searchTerm, category) => {
            return favorites.filter(fav => {
                const matchesSearch = !searchTerm || 
                    fav.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    fav.summary?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    (fav.tags && fav.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())));
                
                const matchesCategory = !category || fav.category === category;
                
                return matchesSearch && matchesCategory;
            });
        };

        const handleFavoritesUpdate = (event) => {
            const { favorites: updatedFavorites } = event.detail;
            setFavorites(updatedFavorites);
            // 如果有过滤条件，也更新过滤后的列表
            if (searchTerm || activeCategory) {  // 使用 activeCategory 替代 selectedCategory
                const filtered = filterFavorites(updatedFavorites, searchTerm, activeCategory);
                setFilteredFavorites(filtered);
            } else {
                setFilteredFavorites(updatedFavorites);
            }
        };

        window.addEventListener('favoritesUpdated', handleFavoritesUpdate);

        return () => {
            window.removeEventListener('favoritesUpdated', handleFavoritesUpdate);
        };
    }, [setFavorites, setFilteredFavorites, searchTerm, activeCategory]); // 使用 activeCategory 作为依赖

    useEffect(() => {
        const handleClickOutside = (event) => {
            // 检查点击是否在分类选择器内部、编辑按钮上
            const isClickInsideSelector = event.target.closest('.category-selector-overlay');
            const isClickOnCategoryButton = event.target.closest('.category-action-btn');
            
            // 检查点击是否在内容卡片的滚动条上
            const contentCard = event.target.closest('.content-card');
            const isClickOnContentCardScrollbar = contentCard && (
                event.offsetX >= contentCard.clientWidth || 
                event.offsetY >= contentCard.clientHeight
            );
            
            // 如果点击在选择器外部，且不是内容卡片的滚动条，且不是编辑按钮，则关闭菜单
            if (editingItemUrl && 
                !isClickInsideSelector && 
                !isClickOnContentCardScrollbar &&
                !isClickOnCategoryButton) {
                setEditingItemUrl(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editingItemUrl]);

    // 添加事件监听器来处理分类删除
    useEffect(() => {
        const handleCategoryDeleted = (event) => {
            const deletedCategory = event.detail.category;
            
            // 更新分类数据
            setCategoriesData(prevData => 
                prevData.filter(cat => cat.name !== deletedCategory)
            );

            // 如果删除的是当前显示的分类，关闭分类下拉菜单
            if (showCategories) {
                setShowCategories(false);
            }
        };

        window.addEventListener('categoryDeleted', handleCategoryDeleted);
        
        return () => {
            window.removeEventListener('categoryDeleted', handleCategoryDeleted);
        };
    }, [showCategories]);

    // 添加一个函数来计算每个分类的收藏数量
    const getCategoryCount = (categoryName) => {
        return favorites.filter(fav => fav.category === categoryName).length;
    };

    // 过滤分类的函数
    const filteredCategories = categoriesData.filter(cat => 
        cat.name.toLowerCase().includes(categorySearchTerm.toLowerCase())
    );

    // Add function to open tag editing dialog
    const openTagEditor = (url) => {
        const favorite = favorites.find(fav => fav.url === url);
        if (favorite) {
            setEditingTagsUrl(url);
            setCurrentTags(favorite.tags || []);
            setNewTagText('');
        }
    };
    
    // Add function to close tag editing dialog
    const closeTagEditor = () => {
        setEditingTagsUrl(null);
        setCurrentTags([]);
        setNewTagText('');
    };
    
    // Handler functions for tag operations
    const handleAddTag = () => {
        addTag(
            newTagText, 
            editingTagsUrl, 
            favorites, 
            setCurrentTags, 
            setFavorites, 
            setFilteredFavorites, 
            setNewTagText
        );
    };
    
    const handleRemoveTag = (tagToRemove) => {
        removeTag(
            tagToRemove, 
            editingTagsUrl, 
            favorites, 
            setCurrentTags, 
            setFavorites, 
            setFilteredFavorites
        );
    };

    return (
        <div className="card-content">
            {activeCategory && (
                <div className="active-category">
                    <span>Selected: {activeCategory}</span>
                    <button 
                        className="clear-button"
                        onClick={clearCategorySelection}
                    >
                        ✖
                    </button>
                </div>
            )}

            <div className="favorites-header">
                <div className="header-content">
                    <h2>Favorites:</h2>
                    <div className="categories-dropdown" ref={dropdownRef}>
                        <button 
                            className="all-categories-btn"
                            onClick={() => setShowCategories(!showCategories)}
                        >
                            All Categories
                        </button>
                        {showCategories && (
                            <div className="categories-dropdown-content">
                                <div className="categories-header">
                                    <div className="category-search">
                                        <FaSearch className="search-icon" style={{ color: ICON_COLORS.search }} />
                                        <input
                                            type="text"
                                            placeholder="Search categories..."
                                            value={categorySearchTerm}
                                            onChange={(e) => setCategorySearchTerm(e.target.value)}
                                            className="category-search-input"
                                        />
                                    </div>
                                    <div className="header-actions">
                                        <div className="category-count-toggle">
                                            <label className="toggle-label" title="Show number of items in each category">
                                                <FaHashtag size={12} style={{ color: ICON_COLORS.hash }} />
                                                <input
                                                    type="checkbox"
                                                    checked={showCategoryCount}
                                                    onChange={(e) => setShowCategoryCount(e.target.checked)}
                                                />
                                                <span className="toggle-slider"></span>
                                            </label>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <button 
                                                className="add-category-button"
                                                onClick={() => setShowNewCategoryInput(!showNewCategoryInput)}
                                                title="Add new category"
                                            >
                                                <FaPlus size={14} style={{ color: ICON_COLORS.plus }} />
                                            </button>
                                            {showNewCategoryInput && (
                                                <div className="new-category-form">
                                                    <input
                                                        type="text"
                                                        value={newCategoryName}
                                                        onChange={(e) => setNewCategoryName(e.target.value)}
                                                        placeholder="Enter category name"
                                                        className="new-category-input"
                                                        autoFocus
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleNewCategorySubmit();
                                                            }
                                                        }}
                                                    />
                                                    <div className="new-category-actions">
                                                        <button 
                                                            onClick={() => {
                                                                setShowNewCategoryInput(false);
                                                                setNewCategoryName('');
                                                            }}
                                                            className="cancel-btn"
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button 
                                                            onClick={handleNewCategorySubmit}
                                                            className={`create-btn ${isCreating ? 'loading' : ''}`}
                                                            disabled={isCreating}
                                                        >
                                                            {isCreating ? 'Creating...' : 'Create'}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <ul className="category-list">
                                    {filteredCategories.map((cat, index) => (
                                        <li
                                            key={index}
                                            className={`category-item ${activeCategory === cat.name ? 'active' : ''}`}
                                        >
                                            {editingCategory === cat.name ? (
                                                <div className="category-edit-container">
                                                    <input
                                                        type="text"
                                                        value={newCategoryName}
                                                        onChange={(e) => setNewCategoryName(e.target.value)}
                                                        onKeyPress={async (e) => {
                                                            if (e.key === 'Enter') {
                                                                await handleConfirmRename(cat);
                                                            }
                                                        }}
                                                        autoFocus
                                                        className="category-edit-input"
                                                    />
                                                    <div className="category-edit-actions">
                                                        <button
                                                            className="confirm-edit-btn"
                                                            onClick={() => handleConfirmRename(cat)}
                                                            title="Confirm"
                                                        >
                                                            ✓
                                                        </button>
                                                        <button
                                                            className="cancel-edit-btn"
                                                            onClick={() => {
                                                                setEditingCategory(null);
                                                                setNewCategoryName(cat.name);
                                                            }}
                                                            title="Cancel"
                                                        >
                                                            ✕
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    {cat.icon_url && (
                                                        <img 
                                                            src={cat.icon_url} 
                                                            alt={`${cat.name} icon`}
                                                            className="category-icon"
                                                            key={cat.icon_url}
                                                        />
                                                    )}
                                                    <div className="category-item-content">
                                                        <span 
                                                            className="category-text"
                                                            onClick={() => {
                                                                handleCategoryClick(cat.name);
                                                                setShowCategories(false);
                                                            }}
                                                        >
                                                            {cat.name}
                                                        </span>
                                                        {showCategoryCount && (
                                                            <span className="category-count">
                                                                {getCategoryCount(cat.name)}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="category-actions">
                                                        <button
                                                            className="edit-category-btn"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setEditingCategory(cat.name);
                                                                setNewCategoryName(cat.name);
                                                            }}
                                                            title="Rename category"
                                                        >
                                                            ✎
                                                        </button>
                                                        <button
                                                            className="delete-category-btn"
                                                            onClick={(e) => handleCategoryDelete(cat.name, e)}
                                                            disabled={isDeleting}
                                                            title="Delete category"
                                                        >
                                                            <FaTrash size={14} />
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="favorites-content">
                {favorites.length > 0 && (
                    <input
                        type="text"
                        placeholder="Search titles, summaries, tags..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                )}

                {favorites.length === 0 ? (
                    <div className="no-favorites">
                        <i className="fas fa-arrow-up"></i>
                        <p>You haven't summarized any articles yet! Paste a link above to get started.</p>
                    </div>
                ) : filteredFavorites.length === 0 ? (
                    <p>No matching favorites found.</p>
                ) : (
                    <>
                        {/* 找到展开项的索引 */}
                        {(() => {
                            const expandedIndex = filteredFavorites.findIndex(fav => fav.url === expandedItemUrl);
                            
                            return (
                                <>
                                    <Masonry
                                        breakpointCols={{
                                            default: 2,
                                            768: 1
                                        }}
                                        className="favorites-list"
                                        columnClassName="favorites-list-column"
                                    >
                                        {filteredFavorites.slice(0, expandedIndex !== -1 ? expandedIndex : undefined).map((fav, index) => (
                                            renderFavoriteItem(fav, index, false)
                                        ))}
                                    </Masonry>

                                    {/* 展开的项目 */}
                                    {expandedIndex !== -1 && (
                                        <div className="expanded-item-container">
                                            {renderFavoriteItem(
                                                filteredFavorites[expandedIndex], 
                                                expandedIndex, 
                                                true
                                            )}
                                        </div>
                                    )}

                                    {/* 展开项之后的内容 */}
                                    {expandedIndex !== -1 && (
                                        <Masonry
                                            breakpointCols={{
                                                default: 2,
                                                768: 1
                                            }}
                                            className="favorites-list"
                                            columnClassName="favorites-list-column"
                                        >
                                            {filteredFavorites.slice(expandedIndex + 1).map((fav, index) => (
                                                renderFavoriteItem(
                                                    fav, 
                                                    expandedIndex + index + 1, 
                                                    false
                                                )
                                            ))}
                                        </Masonry>
                                    )}
                                </>
                            );
                        })()}
                    </>
                )}
            </div>

            {/* Add the Tag Editor Dialog */}
            {editingTagsUrl && (
                <TagEditorDialog
                    editingTagsUrl={editingTagsUrl}
                    favorites={favorites}
                    currentTags={currentTags}
                    newTagText={newTagText}
                    setNewTagText={setNewTagText}
                    closeTagEditor={closeTagEditor}
                    handleAddTag={handleAddTag}
                    handleRemoveTag={handleRemoveTag}
                />
            )}
        </div>
    );
}

export default FavoritesList;